html {
    height: 100%;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-repeat: no-repeat;
    background: -webkit-linear-gradient(#c0e2f8, #e8f2f8 50%, #c0e2f8);
    background: -moz-linear-gradient(#c0e2f8, #e8f2f8 50%, #c0e2f8);
    background: -ms-linear-gradient(#c0e2f8, #e8f2f8 50%, #c0e2f8);
    background: -o-linear-gradient(#c0e2f8, #e8f2f8 50%, #c0e2f8);
    background: linear-gradient(#c0e2f8, #e8f2f8 50%, #c0e2f8);
}

.App {
    padding: 10px;
}

a, a:visited, a:active {
    color: #218BC3;
    text-decoration: none;
}

a:hover {
    color: #5eb5e0;
}

a.active, a.active:hover {
    color: #C82829;
}

div, h1, p, ul {
    text-align: center;
}

ul {
    list-style: none;
    padding-inline-start: 0;
}

.light {
    color: #5b5b5b;
}

.right {
    text-align: right;
}

#logo-container {
    margin-top: 5rem;
    align-items: center;
}

#logo-container-small {
    margin-top: 5rem;
    align-items: center;
}

#logo-image {
    height: 90%;
    width: 90%;
}

#menu {
    right: 1rem;
    top: 0.5rem;
    margin: auto;
    border: 0.2rem solid #218BC3;
    color: #218BC3;
    font-weight: bold;
    align-items: center;
    text-align: center;
    max-width: 12.8rem;
}

.mobile #menu {
    max-width: 25.6rem;
    border: 0.4rem solid #218BC3;
    font-size: 24px;
}

.mobile #menu li {
    border-bottom: 0.2rem dashed #218BC3;
    max-width: 160rem;
    padding: 0.8rem;
}

.right {
    text-align: right;
}

.left {
    text-align: left;
}

#menu li {
    border-bottom: 0.1rem dashed #218BC3;
    color: #218BC3;
    font-weight: bold;
    align-items: center;
    max-width: 80rem;
    margin: auto;
    text-align: center;
    padding: 0.4rem;
    background-color: white;
}

#menu li.last {
    border-bottom: none;
}

#menu li:hover {
    background-color: #5eb5e0;
    cursor: pointer;
}
#menu li:hover {
    color: #ffffff;
}
#menu a.active li {
    color: #C82829;
}

/* For mobile phones: */
[class*="col-"] {
    width: 100%;
    float: left;
}


@media only screen and (min-width: 1024px) {
    /* For desktop: */
    .col-1 {
        width: 8.33%;
    }

    .col-2 {
        width: 16.66%;
    }

    .col-3 {
        width: 25%;
    }

    .col-4 {
        width: 33.33%;
    }

    .col-5 {
        width: 41.66%;
    }

    .col-6 {
        width: 50%;
    }

    .col-7 {
        width: 58.33%;
    }

    .col-8 {
        width: 66.66%;
    }

    .col-9 {
        width: 75%;
    }

    .col-10 {
        width: 83.33%;
    }

    .col-11 {
        width: 91.66%;
    }

    .col-12 {
        width: 100%;
    }

    #logo-image {
        width: 30rem;
    }
}

/* 48rem main-container width + 2 x 20rem menu width */
@media only screen and (max-width: 1023px) {
    #contact-container .right, #contact-container .left {
        text-align: center;
    }
}

/* Super mole */
#appIconImage {
    height: 12.8rem;
    width: 12.8rem;
    border-radius: 2.4rem;
}

#downloadAppStoreImg {
    height: 5rem;
    margin-right: 20px;
}

#downloadAppStoreImg.est {
    margin-left: -10px;
}
#downloadAppStoreImg.eng {
    margin-left: 30px;
}

#downloadPlayStoreImg {
    height: 5rem;
}

#appScreenshotImage {
    width: 12.8rem;
}