.table-center {
  margin: auto;
}

.rarity-cell {
  border: 1px solid black;
  border-radius: 15px;
  padding: 5px;
}

#downloadAppStoreImg {
  opacity: 0.5;
}