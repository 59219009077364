body, #root, .App, .video-grid {
  height: 99%;
  margin: 0;
}

.video-grid {
  display: grid;
  height: 100%; /* Fill the whole screen */
  gap: 5px; /* Add a small gap between videos */
}

.cols-count-2 {
  grid-template-columns: 1fr 1fr; /* Two columns */
}
.rows-count-2 {
  grid-template-rows: 1fr 1fr; /* Two rows */
}

.video-grid iframe {
  width: 100%;
  height: 100%;
}