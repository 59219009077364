.numberInput {
  width: 48px;
  background-color: #c0e2f8;
  color: #5b5b5b;
  font-size: 12px;
  padding: 2px;
  text-align: center;
  margin: 2px;
  border-radius: 8px;
}

#energyTable {
  margin: 10px auto 25px;

  th {
    border-bottom: 1px solid #b1e0f8;
  }
  td {
    padding: 3px;
    border-left: 1px solid #b1e0f8;
    border-right: 1px solid #b1e0f8;
  }
  .sortable {
    cursor: pointer;
  }
  .active {
    cursor: default;
    background-color: #b1e0f8;
  }
}