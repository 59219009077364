$size: 10px;

.diff-indicator-container {
  display: inline-block;
}
.diff-indicator-indicator {
  float: left;
  width: $size;
  height: $size;
  border: 1px solid black;
  border-radius: 3px;
}
