.language {
  height: 30px;
  width: 30px;
  resize: both;
  margin: 10px;
  border-radius: 30px;
  border: 2px solid black;
}

.language.active {
  border: 2px solid #af051b;
}

.mobile .language {
  height: 60px;
  width: 60px;
  margin: 20px;
  border-radius: 60px;
  border: 4px solid black;
}

.mobile .language.active {
  border: 4px solid #af051b;
}